<template>
  <v-card
    v-if="mission.id"
    class="px-2 pb-0 cursor"
    :class="topBorderClass"
    @click="openInfo"
    tile
    :ripple="false"
  >
    <v-container class="px-3 py-0">
      <v-card-title
        class="no-flex-wrap pt-2 pl-0 mb-2"
        v-bind:class="{ 'pb-0': isLeader, 'pb-2': !isLeader }"
      >
        <v-chip
          v-if="!isLeader && mission.group"
          class="ml-0"
          small
          label
          color="app-green"
          dark
        >
          <span class="text-body-2 font-weight-medium">
            {{ mission.group.name }}
          </span>
        </v-chip>
      </v-card-title>
      <v-row align="center">
        <v-col
          class="pt-0"
          v-bind:class="{
            'col-12': !mission.requireApprove,
            'col-11': mission.requireApprove
          }"
        >
          <v-card-title class="text-h6 pl-0 pt-0 pb-0">
            <span
              v-if="!isDetailPage"
              @click.stop="navigateToDetailPage"
              class="text-h6 font-weight-regular two-lines app-blue--text router-link text-truncate"
            >
              {{ mission.name }}
            </span>
            <span v-else class="two-lines font-weight-regular">
              {{ mission.name }}
            </span>
          </v-card-title>
          <div class="text-subtitle-1 app-dark-gray--text font-weight-regular">
            {{ mission.notes }}
          </div>
          <span class="text-body-2 font-weight-light">
            {{ mission.from | dateFormat }} -
            {{ mission.to | dateFormat }}
          </span>
        </v-col>
        <v-col v-if="mission.requireApprove" cols="1" class="pt-0 px-0">
          <v-icon v-if="!approveMarked" small color="app-orange">
            mdi-circle
          </v-icon>
          <v-icon v-else-if="mission.approved" small color="app-green">
            mdi-thumb-up
          </v-icon>
          <v-icon v-else small color="app-red">mdi-thumb-down</v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-0 pb-1 px-2">
      <mission-progress-bar :mission="mission"></mission-progress-bar>
    </v-card-actions>
    <v-card-actions class="pt-1">
      <mission-status-label :mission="mission"></mission-status-label>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-3">
        <ruby-label
          :ruby="mission.ruby"
          :text-color="textRubyColor"
        ></ruby-label>
      </v-row>
    </v-card-actions>
    <v-divider class="mt-2" light v-if="toggleApproval"></v-divider>
    <v-card-actions v-if="toggleApproval" class="justify-center">
      <approval-actions @approve="approveMission"></approval-actions>
    </v-card-actions>
  </v-card>
</template>

<script>
import { APPROVE_GROUP_MISSION } from '../../../store/missions/types';
import GroupMissionInfoModal from '../../groups/missions/GroupMissionInfoModal';
import MissionProgressBar from '../../missions/MissionProgressBar';
import MissionStatusLabel from '../../missions/MissionStatusLabel';
import RubyLabel from '../../shared/RubyLabel';
import ApprovalActions from '../../groups/ApprovalActions';
import missionRubyColorMixin from '../../../mixins/mission.ruby.color.mixin';

const GROUP_MISSION_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'member-mission',
  components: {
    MissionProgressBar,
    MissionStatusLabel,
    RubyLabel,
    ApprovalActions
  },
  props: {
    mission: {
      type: Object,
      required: true
    },
    isDetailPage: {
      type: Boolean,
      default: false
    },
    topBorderClass: {
      type: String,
      default: 'border-top-blue'
    },
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    approveMarked() {
      return (
        this.mission.approved !== null && this.mission.approved !== undefined
      );
    },
    toggleApproval() {
      return (
        this.isLeader &&
        this.mission.requireApprove &&
        this.mission.approveRequested
      );
    }
  },
  mixins: [missionRubyColorMixin],
  methods: {
    openInfo() {
      this.$root.$modal.show(
        GroupMissionInfoModal,
        {
          mission: this.mission
        },
        GROUP_MISSION_INFO_MODAL_CONFIG
      );
    },
    async approveMission(approved) {
      const { id } = this.$route.params;

      this.$store.dispatch(APPROVE_GROUP_MISSION, {
        groupId: id,
        userId: this.mission.userId,
        missionId: this.mission.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('missions.missionApproved')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('missions.missionRefused')
        });
      }
    },
    navigateToDetailPage() {
      this.$router.push({ path: `/missions/${this.mission.id}` });
    }
  }
};
</script>
