<template>
  <div>
    <v-btn
      text
      icon
      color="app-red"
      class="mr-3"
      @click.stop="$emit('approve', false)"
    >
      <v-icon color="app-red">mdi-thumb-down</v-icon>
    </v-btn>
    <v-btn text icon color="app-green" @click.stop="$emit('approve', true)">
      <v-icon color="app-green">mdi-thumb-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'approval-actions'
};
</script>
